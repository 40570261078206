import dayjs from 'dayjs'

import './cadesplugin_api'

const gost256Value = '1.2.643.7.1.1.1.1'
const gost512Value = '1.2.643.7.1.1.1.2'
const gost2001Value = '1.2.643.2.2.19'
const OID_ADMINISTRATOR_CERTIFICATE = '1.2.643.6.57.1.5.2.3'
const OID_MANAGER_CERTIFICATE = '1.2.643.6.57.1.5.2.4'

export const CertificateUtils = {
    gost256Value,
    gost512Value,
    gost2001Value,

    checkAllCertificatesAsync() {
        return new Promise(function (resolve, reject) {
            cadesplugin.async_spawn(
                function* ([cb]) {
                    var certList = []
                    var oStore = yield cadesplugin.CreateObjectAsync('CAdESCOM.Store')
                    if (!oStore) {
                        return
                    }

                    try {
                        yield oStore.Open()
                    } catch (ex) {
                        return
                    }

                    var certCnt
                    var certs

                    try {
                        certs = yield oStore.Certificates
                        certCnt = yield certs.Count
                    } catch (ex) {
                        return
                    }

                    if (certCnt === 0) {
                        return
                    }

                    for (var i = 1; i <= certCnt; i++) {
                        var certRes = {
                            text: null,
                            thumbprint: null,

                            subject: null,
                            expired_at: null,

                            checkNotBeforeDateFrom: null,
                            checkNotAfterDateTo: null,
                            checkPrivateKey: null,
                            checkIsValid: null,
                            checkAlgorithmValue: null,
                            checkExtendedKeyUsage: null,
                            checkKeyUsage: null,

                            algorithmName: null,
                            // validFromDate: null,
                            // validToDate: null
                        }

                        var cert
                        var ValidToDate, ValidFromDate

                        try {
                            cert = yield certs.Item(i)

                            ValidToDate = new Date(yield cert.ValidToDate)
                            ValidFromDate = new Date(yield cert.ValidFromDate)
                            certRes.expired_at = yield cert.ValidToDate

                            certRes.text = new CertificateAdjuster().GetCertInfoString(
                                yield cert.SubjectName,
                                ValidFromDate,
                            )
                            certRes.thumbprint = yield cert.Thumbprint
                            certRes.subject = yield cert.SubjectName
                        } catch (e) {
                            console.error(e)
                            continue
                        }

                        // 1. Срок действия сертификата
                        try {
                            certRes.checkNotBeforeDateFrom = checkNotBeforeDateFrom(ValidFromDate)
                            certRes.checkNotAfterDateTo = checkNotAfterDateTo(ValidToDate)

                            // certRes.validToDate = ValidToDate
                            // certRes.validFromDate = ValidFromDate
                        } catch (e) {
                            console.error(e)
                        }

                        // 2. Наличие закрытого ключа
                        try {
                            certRes.checkPrivateKey = yield cert.HasPrivateKey()
                        } catch (e) {
                            console.error(e)
                        }

                        // 3. Проверка цепочки сертификата
                        try {
                            var Validator = yield cert.IsValid()
                            certRes.checkIsValid = yield Validator.Result
                        } catch (e) {
                            console.error(e)
                        }

                        // 4. Проверка указанного в сертификате алгоритма для открытого ключа
                        try {
                            var publicKey = yield cert.PublicKey()
                            var algorithm = yield publicKey.Algorithm

                            certRes.checkAlgorithmValue = checkAlgorithmValue(yield algorithm.Value)
                            certRes.algorithmName = yield algorithm.FriendlyName
                        } catch (e) {
                            console.error(e)
                        }

                        // 5. Проверка значений Расширенное использование Ключа
                        try {
                            var eku = yield cert.ExtendedKeyUsage()
                            var ekus = yield eku.EKUs
                            var ekusCount = yield ekus.Count

                            var ekuList = []

                            for (var j = 1; j <= ekusCount; ++j) {
                                var ekuItem = yield ekus.Item(j)
                                var ekuOID = yield ekuItem.OID

                                ekuList.push(ekuOID)
                            }

                            certRes.checkExtendedKeyUsage = checkExtendedKeyUsage(ekuList)
                        } catch (e) {
                            console.error(e)
                        }

                        // 6. Проверка KeyUsage
                        try {
                            var usage = yield cert.KeyUsage()

                            var digitalSignature = yield usage.IsDigitalSignatureEnabled
                            var nonRepudiationEnabled = yield usage.IsNonRepudiationEnabled

                            certRes.checkKeyUsage = checkKeyUsage(
                                digitalSignature,
                                nonRepudiationEnabled,
                            )
                        } catch (e) {
                            console.error(e)
                        }

                        certList.push(certRes)
                    }

                    yield oStore.Close()

                    cb(certList)
                },
                resolve,
                reject,
            )
        })

        function checkKeyUsage(digitalSignature, nonRepudiationEnabled) {
            return digitalSignature === 1 && nonRepudiationEnabled === 1
        }
    },

    checkAllCertificatesSync() {
        return new Promise(function (resolve, reject) {
            try {
                var certList = []

                var oStore = cadesplugin.CreateObject('CAdESCOM.Store')
                if (!oStore) {
                    throw new Error('Cannot create CAdESCOM.Store object')
                }

                oStore.Open()

                var certs = oStore.Certificates
                var certCnt = certs.Count

                for (var i = 1; i <= certCnt; i++) {
                    var certRes = {
                        text: null,
                        thumbprint: null,

                        checkNotBeforeDateFrom: null,
                        checkNotAfterDateTo: null,
                        checkPrivateKey: null,
                        checkIsValid: null,
                        checkAlgorithmValue: null,
                        checkExtendedKeyUsage: null,
                        checkKeyUsage: null,

                        algorithmName: null,
                        // validFromDate: null,
                        // validToDate: null
                    }

                    var cert
                    var ValidToDate, ValidFromDate

                    try {
                        cert = certs.Item(i)

                        ValidToDate = new Date(cert.ValidToDate)
                        ValidFromDate = new Date(cert.ValidFromDate)

                        certRes.text = new CertificateAdjuster().GetCertInfoString(
                            cert.SubjectName,
                            ValidFromDate,
                        )
                        certRes.thumbprint = cert.Thumbprint
                        // NOTE: Хак для IE11 чтобы он мог сделать переносы в слишком длинных строках
                        // (вставляем символ ZERO WIDTH SPACE после каждого печатного символа)
                        certRes.text = certRes.text.split('').join('\u200b')
                    } catch (e) {
                        console.error(e)
                        continue
                    }

                    // 1. Срок действия сертификата
                    try {
                        certRes.checkNotBeforeDateFrom = checkNotBeforeDateFrom(ValidFromDate)
                        certRes.checkNotAfterDateTo = checkNotAfterDateTo(ValidToDate)

                        // certRes.validToDate = ValidToDate
                        // certRes.validFromDate = ValidFromDate
                    } catch (e) {
                        console.error(e)
                    }

                    // 2. Наличие закрытого ключа
                    try {
                        certRes.checkPrivateKey = cert.HasPrivateKey()
                    } catch (e) {
                        console.error(e)
                    }

                    // 3. Проверка цепочки сертификата
                    try {
                        var Validator = cert.IsValid()
                        certRes.checkIsValid = Validator.Result
                    } catch (e) {
                        console.error(e)
                    }

                    // 4. Проверка указанного в сертификате алгоритма для открытого ключа
                    try {
                        var publicKey = cert.PublicKey()
                        var algorithm = publicKey.Algorithm

                        certRes.checkAlgorithmValue = checkAlgorithmValue(algorithm.Value)
                        certRes.algorithmName = algorithm.FriendlyName
                    } catch (e) {
                        console.error(e)
                    }

                    // 5. Проверка значений Расширенное использование Ключа
                    try {
                        var eku = cert.ExtendedKeyUsage()
                        var ekus = eku.EKUs
                        var ekusCount = ekus.Count

                        var ekuList = []

                        for (var j = 1; j <= ekusCount; ++j) {
                            var ekuItem = ekus.Item(j)
                            var ekuOID = ekuItem.OID

                            ekuList.push(ekuOID)
                        }

                        certRes.checkExtendedKeyUsage = checkExtendedKeyUsage(ekuList)
                    } catch (e) {
                        console.error(e)
                    }

                    // 6. Проверка KeyUsage
                    try {
                        var usage = cert.KeyUsage()

                        var digitalSignature = usage.IsDigitalSignatureEnabled
                        var nonRepudiationEnabled = usage.IsNonRepudiationEnabled

                        certRes.checkKeyUsage = checkKeyUsage(
                            digitalSignature,
                            nonRepudiationEnabled,
                        )
                    } catch (e) {
                        console.error(e)
                    }

                    certList.push(certRes)
                }

                oStore.Close()

                resolve(certList)
            } catch (e) {
                console.error(e)
                return reject(e)
            }
        })

        function checkKeyUsage(digitalSignature, nonRepudiationEnabled) {
            return digitalSignature == true && nonRepudiationEnabled == true
        }
    },
}

function checkAlgorithmValue(algorithmValue) {
    return [gost256Value, gost512Value, gost2001Value].indexOf(algorithmValue) !== -1
}

function checkNotBeforeDateFrom(validFromDate) {
    return !dayjs().isBefore(validFromDate)
}

function checkNotAfterDateTo(validToDate) {
    return !dayjs().isAfter(validToDate)
}

function checkExtendedKeyUsage(ekuList) {
    return (
        ekuList.includes(OID_ADMINISTRATOR_CERTIFICATE) || ekuList.includes(OID_MANAGER_CERTIFICATE)
    )
}

function CertificateAdjuster() {}

CertificateAdjuster.prototype.extract = function (from, what) {
    let certName = ''

    var begin = from.indexOf(what)

    if (begin >= 0) {
        var end = from.indexOf(', ', begin)
        certName = end < 0 ? from.substr(begin) : from.substr(begin, end - begin)
    }

    return certName
}

CertificateAdjuster.prototype.Print2Digit = function (digit) {
    return digit < 10 ? '0' + digit : digit
}

CertificateAdjuster.prototype.GetCertDate = function (paramDate) {
    var certDate = new Date(paramDate)
    return (
        this.Print2Digit(certDate.getUTCDate()) +
        '.' +
        this.Print2Digit(certDate.getMonth() + 1) +
        '.' +
        certDate.getFullYear() +
        ' ' +
        this.Print2Digit(certDate.getUTCHours()) +
        ':' +
        this.Print2Digit(certDate.getUTCMinutes()) +
        ':' +
        this.Print2Digit(certDate.getUTCSeconds())
    )
}

CertificateAdjuster.prototype.GetCertInfoString = function (certSubjectName, certFromDate) {
    return this.extract(certSubjectName, 'CN=') + '; Выдан: ' + this.GetCertDate(certFromDate)
}
